<template>
  <div class="hdTicket">
    <div class="page-header flex">
      <span style="font-size: 16px;">防伪码管理</span>
      <div>
        <div class="screen-btn ant-menu-item-selected" @click="_addTodo">
          <a-icon type="plus-circle" />
          <span>新增</span>
        </div>
        <div class="screen-btn ant-menu-item-selected" @click="_makeTodo">
          <a-icon type="plus-circle" />
          <span>生成</span>
        </div>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="screen-list flex">
        <div class="screen-list-item flex">
          <span class="screen-item-label">搜索卡号</span>
          <a-input
            placeholder="请输入关键字"
            style="flex: 1; height: 36px;"
            v-model="SearchCardNum"
          />
        </div>

        <span class="screen-item-label">验证状态:</span>
        <a-select
          placeholder="验证状态"
          v-model="ValidateStatus"
          style="width: 250px;"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="0">未验证</a-select-option>
          <a-select-option value="1">已验证</a-select-option>
        </a-select>

        <span class="screen-item-label">使用状态:</span>
        <a-select
          placeholder="使用状态"
          v-model="UseStatus"
          style="width: 250px;"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="0">未使用</a-select-option>
          <a-select-option value="1">已使用</a-select-option>
        </a-select>
      </div>
      <div class="todo-content">
        <div style="text-align: right; margin: 15px 0; padding: 5px 10px;">
          <a-button
            style="width: 110px; height: 34px; margin-right: 20px;"
            @click="_resetBtn"
            >重置条件</a-button
          >
          <a-button
            type="primary"
            style="width: 110px; height: 34px;"
            @click="_searchBtn"
            >开始查询</a-button
          >
        </div>
        <a-table
          :columns="columns"
          :dataSource="infoData"
          :pagination="false"
          bordered
          v-if="infoData"
        >
          <div slot="Operation" slot-scope="text, index">
            <a-popconfirm
              title="您确定要删除吗?"
              @confirm="_delTableRow(index)"
              okText="确定"
              cancelText="取消"
            >
              <i
                class="iconfont icon-shanchu- icon-btn"
                style="font-size: 20px;"
              ></i>
            </a-popconfirm>
          </div>
        </a-table>

        <div style="margin: 20px 0; text-align: right;">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="pageTotal"
            @change="_PageChange"
          />
        </div>
      </div>
      <a-drawer
        :title="drawerAlertTitle"
        placement="right"
        :closable="false"
        @close="onClose"
        :visible="visible"
        width="450"
      >
        <a-form layout="vertical" hideRequiredMark>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'卡号'" :required="true">
                <a-input
                  style="width: 250px;"
                  placeholder="请输入"
                  v-model="CardNum"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'分类'">
                <a-select
                  style="width: 250px;"
                  v-model="Category"
                  placeholder="请选择"
                  @change="_ChangeCategory"
                >
                  <a-select-option disabled selected hidden value=""
                    >请选择</a-select-option
                  >
                  <a-select-option
                    :value="item.Id"
                    v-for="(item, index) in CategoryName"
                    :key="index"
                    >{{ item.Name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'使用状态'">
                <a-select
                  placeholder="使用状态"
                  v-model="UseStatus"
                  style="width: 250px;"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">未使用</a-select-option>
                  <a-select-option value="1">已使用</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'所在状态'">
                <a-select
                  placeholder="验证状态"
                  v-model="ValidateStatus"
                  style="width: 250px;"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">未验证</a-select-option>
                  <a-select-option value="1">已验证</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'创建人'">
                <a-input
                  style="width: 250px;"
                  v-model="CreateUserName"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="drawer-bottom">
          <a-button
            style="width: 120px; text-align: center; margin-right: 20px;"
            @click="onClose"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 120px; text-align: center;"
            @click="_saveTodo"
          >
            提交
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
        :title="drawerAlertTitle"
        placement="right"
        :closable="false"
        @close="onMakeClose"
        :visible="visibleMake"
        width="450"
      >
        <a-form layout="vertical" hideRequiredMark>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'生成数量'" :required="true">
                <a-input
                  style="width: 250px;"
                  placeholder="请输入"
                  v-model="Nums"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'分类'">
                <a-select
                  style="width: 250px;"
                  v-model="Category"
                  placeholder="请选择"
                  @change="_ChangeCategory"
                >
                  <a-select-option disabled selected hidden value=""
                    >请选择</a-select-option
                  >
                  <a-select-option
                    :value="item.Id"
                    v-for="(item, index) in CategoryName"
                    :key="index"
                    >{{ item.Name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="'创建人'">
                <a-input
                  style="width: 250px;"
                  v-model="CreateUserName"
                  disabled
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="drawer-bottom">
          <a-button
            style="width: 120px; text-align: center; margin-right: 20px;"
            @click="onMakeClose"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 120px; text-align: center;"
            @click="_saveMakeTodo"
            >提交</a-button
          >
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import axios from '@/assets/axios/axios'
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

const columns = [
  {
    title: '序号',
    dataIndex: 'sNumber',
    align: 'center',
  },
  {
    title: '卡号',
    dataIndex: 'CardNumText',

    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'Name',

    align: 'center',
  },
  {
    title: '使用的凭证',
    dataIndex: 'PayVoucher',

    align: 'center',
  },
  {
    title: '使用状态',
    dataIndex: 'UseStatusText',

    align: 'center',
  },
  {
    title: '使用时间',
    dataIndex: 'UseTime',

    align: 'center',
  },
  {
    title: '验证状态',
    dataIndex: 'ValidateStatusText',

    align: 'center',
  },
  {
    title: '验证时间',
    dataIndex: 'ValidateTime',

    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'CreateUserName',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'CreateTime',

    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'Operation',
    align: 'center',
    scopedSlots: {
      customRender: 'Operation',
    },
  },
]
const userinfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'hdTicket',
  data() {
    return {
      index: 1,
      tabPosition: 'top',
      columns: columns,
      startDate: '',
      endDate: '',
      infoData: [],
      pageTotal: 0,
      page: 1,
      visible: false,
      visibleMake: false,

      startEndDate: [],
      alertFileList: [],
      drawerAlertTitle: '新增防伪码',

      ValidateStatus: undefined,
      UseStatus: undefined,
      SearchCardNum: '',
      CardNum: '',
      CreateUserName: '',
      userName: '',
      CategoryName: [],
      Nums: 1,
      Category: '',
      CategoryId: '',
    }
  },
  created() {
    this._info(this.page)
    //获取分类
  },
  mounted() {
    this.$axios.post(80800005, {}, (res) => {
      if (res.data.code == 1) {
        this.CategoryName = res.data.data
      }
    })
    this._getUserName()
  },
  methods: {
    _ChangeCategory(i) {
      this.CategoryId = i
    },
    _getUserName() {
      // this.userName = JSON.parse(window.sessionStorage.getItem('userInfo'))
      this.CreateUserName = userinfo.name
      this.CreateUserId = userinfo.uid
    },
    _resetBtn() {
      this.ValidateStatus = undefined
      this.UseStatus = undefined
      ;(this.SearchCardNum = ''), (this.index = 1)
    },
    _saveTodo() {
      if (this.CardNum == '') {
        this.$message.error('防伪码卡号必填')
        return false
      } else if (this.CategoryId == '') {
        this.$message.error('所属分类必填')
        return false
      }
      let data = {
        CardNum: this.CardNum,
        CategoryId: this.CategoryId,
        ValidateStatus: this.ValidateStatus,
        UseStatus: this.UseStatus,
        CreateUserId: this.CreateUserId,
        CreateUserName: this.CreateUserName,
      }

      this.$axios.post(80800002, data, (res) => {
        if (res.data.code == 1) {
          this.$message.success('添加成功')
        } else {
          this.$message.error(res.data.msg)
        }
        this._info(1)
        this._onClose()
      })
    },
    _saveMakeTodo() {
      let data = {
        Nums: this.Nums,
        CategoryId: this.CategoryId,
        CreateUserId: this.CreateUserId,
      }
      this.$axios.post(80800003, data, (res) => {
        if (res.data.code == 1) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }

        this._info(1)
        this.onMakeClose()
      })
    },
    _makeTodo() {
      this.visibleMake = true
      this.drawerAlertTitle = '生成防伪码'
    },
    _addTodo() {
      this.visible = true
      this.drawerAlertTitle = '新增防伪码'
    },
    _searchBtn(Page) {
      this.page = 1
      this._info(Page)
    },
    onClose() {
      this.visible = false

      this.ValidateStatus = undefined
      this.UseStatus = undefined
      this.SearchCardNum = ''
      this.CardNum = ''
    },
    onMakeClose() {
      this.visibleMake = false
      this.ValidateStatus = undefined
      this.UseStatus = undefined
      this.SearchCardNum = ''
      this.CardNum = ''
    },
    _info(Page) {
      this.$message.loading('加载中...')
      let data = {
        ValidateStatus: this.ValidateStatus,
        UseStatus: this.UseStatus,
        CardNum: this.SearchCardNum,
        Page: Page,
        PageSize: 10,
      }
      axios.post(80800001, data, (res) => {
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.pageTotal = res.data.count
          this.page += this.page
          this.infoData.map((res, index) => {
            if (Page * 0 != 0) {
              res.sNumber = index + 1
            } else {
              res.sNumber = (Page - 1) * 10 + index + 1
            }
          })
        } else {
          this.pageTotal = 0
          this.infoData = []
        }
        this.$message.destroy()
      })
    },
    _PageChange(pageNumber) {
      this.page = pageNumber
      this._info(pageNumber)
    },
    _createdDate(value) {
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    },
    _delTableRow(index) {
      this.$axios.post(80800004, { Id: index.Id }, (res) => {
        if (res.data.code == 1) {
          this.$message.success('删除成功')

          // this.tableData.splice(index,1);
        } else {
          this.$message.success(res.data.msg)
        }
        this._info(1)
      })
    },
  },
}
</script>

<style scoped lang="less">
.hdTicket {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;
  .page-header {
    height: 55px;
    line-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e2e3;
    ul {
      li {
        width: 100px;
        padding: 8px 0;
        text-align: center;
        color: #666;
        display: inline-block;
        border: 1px solid #e1e2e3;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: none;
        border-right: none;
        cursor: pointer;
        &:last-child {
          border-right: 1px solid #e1e2e3;
        }
      }
      li.active {
        color: #fff !important;
        border: none;
      }
    }
    .screen-btn {
      width: 100px;
      display: inline-block;
      font-size: 12px;
      margin-left: 20px;
      border-width: 1px;
      border-style: solid;
      border-radius: 20px;
      padding: 9px 0;
      line-height: 1;
      text-align: center;
      background: #fff !important;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
  .screen-list {
    margin-top: 10px;
    .screen-list-item {
      margin-right: 20px;
      flex: 1;
    }
    .screen-item-label {
      margin-right: 10px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .icon-btn {
    cursor: pointer;
    margin-right: 15px;
    font-size: 18px;
  }
}
.drawer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
}
.operation-btn {
  font-size: 30px;
  color: #9a9a9a;
  cursor: pointer;
}
.hdTicket-text {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  margin-left: 50px;
  background: #f8f9fa;
  border-radius: 2px;
  color: #999;
  padding-right: 40px;
  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
}
.ant-select-selection {
  height: 36px !important;
}
.upload-file-item {
  width: 100px;
  padding: 15px 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
  border-radius: 2px;
  i {
    font-size: 28px;
    margin-bottom: 10px;
  }
}
.lookBox {
  display: inline-table;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100vh;
  line-height: 100vh;
}
.imgLook {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  z-index: 220;
}
</style>
